import React from 'react'
import {
  Flex,
  Box,
  Heading,
  Text,
} from 'rebass'

import FreeTrialModal from './freeTrialModal'

const BottomHero = (props) => (
  <Box bg={props.bg}>
    <Flex flexWrap='wrap' py={5}>
      <Box mx='auto' style={{
        textAlign: 'center'
      }}>
        <Heading py={2} fontSize={6}>Ready to join Reversity?</Heading>
        <Text color='gray' fontSize={3}>Start a new way of eating that will reverse your disease and restore your health.</Text>
        <Box py={4}>
          <FreeTrialModal variant='desktop'/>
        </Box>
      </Box>
    </Flex>
  </Box>
)

BottomHero.defaultProps = {
  bg: 'white'
}

export default BottomHero

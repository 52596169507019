import React from 'react'
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'

import { Elements } from 'react-stripe-elements'

import {
  Box,
  Button,
  Heading,
  Text
} from 'rebass'

import PaymentForm from './paymentForm'

class FreeTrialModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
    this.hide = this.hide.bind(this)
    this.show = this.show.bind(this)
  }

  show() {
    this.setState({ visible: true })
  }

  hide() {
    this.setState({ visible: false })
  }

  render() {
    const variant = this.props.variant
    let button

    if (variant === 'desktop') {
      button =
        <Button
          variant='primary'
          onClick={this.show}>
          Start your free trial
        </Button>
    } else {
      button =
        <Box style={{
          position: 'fixed',
          bottom: '0',
          left: '0',
          right: '0'
        }}>
          <Box style= {{
          }} mx='auto' p={2}>
            <Button
              onClick={this.show}
              style={{
                boxShadow: '0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)'
              }} borderRadius={5} py={3} variant='cta'>Start your free trial</Button>
          </Box>
        </Box>
    }

    return (
      <div>
        {button}
        <Rodal
          visible={this.state.visible}
          onClose={this.hide}
          closeOnEsc={true}
          animation='slideDown'>
          <Box style={{ textAlign: 'left' }}>
            <Heading>Reversity Free Trial</Heading>
            <Text color='gray' fontWeight='bold'>$199.99/month</Text>
            <Text color='gray' py={2} fontSize={1}>Begin your free 7-day trial of Reversity by entering your payment information below. You will be charged $199.99/month after your 7-day free trial unless you cancel by sending your cancellation request to contact@reversity.io.</Text>
            <Elements>
              <PaymentForm modal={this} planName='custom_reversity_plan' />
            </Elements>
          </Box>
        </Rodal>
      </div>
    )
  }
}

FreeTrialModal.defaultProps = {
  variant: 'desktop'
}

export default FreeTrialModal

import React from 'react'

import {
  Flex,
  Box,
  Text,
  Link
} from 'rebass'

import privacyPolicy from '../files/reversity_privacy_policy.pdf'
import termsOfService from '../files/reversity_terms_of_service.pdf'

import Container from './container'

const Footer = () => (
  <Container>
    <Flex
      p={3}
      alignItems='center'>
      <Box>
        <Text color='gray'>&#169; Reversity 2018</Text>
      </Box>
      <Box mx='auto'></Box>
      <Box px={3}>
        <Link href={termsOfService} target='_blank' rel='noopener noreferrer'>Terms of Service</Link>
      </Box>
      <Box px={3}>
        <Link href={privacyPolicy} target='_blank' rel='noopener noreferrer'>Privacy Policy</Link>
      </Box>
    </Flex>
  </Container>
)

export default Footer

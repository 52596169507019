import React from 'react'

import {
  Text,
  Flex,
  Box,
  Image,
  Card
} from 'rebass'

import Layout from '../../components/layout'
import Container from '../../components/container'
import BottomHero from '../../components/bottomHero'

import reversityUniversity from '../../images/reversity_university.jpg'

import ReversityUniversityModal from '../../components/reversityUniversityModal.js'

const ReversityUniversityPlanPage = () => (
  <Layout>
    <Container>
      {/* Hero */}
      <Flex flexWrap='wrap' p={3} mx={-2}>
        <Box mx='auto'>
          <Text textAlign='center' py={2} fontWeight='bold' fontSize={5}>Reversity University</Text>
        </Box>
      </Flex>

      <Flex flexWrap='wrap' p={3} mx={-2}>
        <Box width={[1, 1/2]} p={2}>
          <Image src={reversityUniversity} alt='Reversity university plan' />
        </Box>

        <Box width={[1, 1/2]} p={2}>
          <Text>Students of Reversity University will receive a weekly set of hand-picked educational resources to support their specific health goals and deepen their understanding of the science of diabetes reversal.</Text>

          <Box py={3}>
            <Text color='gray' fontWeight='bold' py={1}>$49.99/month</Text>
            <ReversityUniversityModal />
          </Box>
        </Box>
      </Flex>
    </Container>

    <Box bg='lightGray' py={5}>
      <Container>
        <Flex flexWrap='wrap' p={3} mx={-2}>
          <Box p={2} width={1}>
            <Card
              p={3}
              variant='primary'
              borderRadius={5}
              color='gray'
              bg='white'>
              Reversity University is a personalized self-study course for anyone who wants to gain a deeper understanding of the scientific and nutritional principles that underlie our program. We all know how difficult it can be to find high-quality, evidence-based, information about nutrition on the Internet. If you want to spend less time on Google and more time on content, this is the plan for you! Every Reversity University course is tailored to your specific areas of interest and health goals.
            </Card>
          </Box>
          <Box p={2} width={1}>
            <Card
              p={3}
              variant='primary'
              borderRadius={5}
              color='gray'
              bg='white'>
              Each week, we will ask you to indicate your area of focus for the upcoming week. You are welcome to either choose from our list of suggested topics or create your own topic related to the science of diabetes reversal and the regulation of body weight. You can keep the same topic for multiple weeks to extend your knowledge with additional in-depth resources or move to a new area of interest.
            </Card>
          </Box>
          <Box p={2} width={1}>
            <Card
              p={3}
              variant='primary'
              borderRadius={5}
              color='gray'
              bg='white'>
              <Text mb={2}>Sample topics include:</Text>
              <Text fontWeight='bold'>Diabetes Reversal: An Introduction</Text>
              <Text fontWeight='bold'>Nutritional Ketosis</Text>
              <Text fontWeight='bold'>Nutritional Ketosis vs. Diabetic Ketoacidosis</Text>
              <Text fontWeight='bold'>Understanding Insulin Resistance</Text>
              <Text fontWeight='bold'>Hormonal Regulation of Weight</Text>
              <Text fontWeight='bold'>Glycemic Index and Carbohydrate Quality</Text>
              <Text fontWeight='bold'>Types of Dietary Fats</Text>
              <Text fontWeight='bold'>The Omega 6:3 Ratio and Heart Disease</Text>
              <Text fontWeight='bold'>Keto, Low-Carb, or Atkins - A Comparison</Text>
            </Card>
          </Box>
          <Box p={2} width={1}>
            <Card
              p={3}
              variant='primary'
              borderRadius={5}
              color='gray'
              bg='white'>
              A collection of curated educational resources (videos, presentations, articles, and interactive quizzes), hand-picked for you by our team of experts, will be delivered to your inbox each week. To accomodate your personal learning style, we ask you to specify your preferred course format: video-only, text-only, or hybrid.
            </Card>
          </Box>
          <Box p={2} width={1}>
            <Card
              p={3}
              variant='primary'
              borderRadius={5}
              color='gray'
              bg='white'>
              Educational and motivational resources to support your journey. We realize that changing your way of eating is an enormous challenge, and we are determined to provide the tools and information to help you succeed. Every few days we will send relevant videos, lectures, and articles, hand-picked by our team of experts to support your health goals and current action plans.
            </Card>
          </Box>
          <Box py={2} mx='auto'>
            <ReversityUniversityModal />
          </Box>
        </Flex>
      </Container>
    </Box>
    <BottomHero />
  </Layout>
)

export default ReversityUniversityPlanPage

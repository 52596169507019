import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import { StripeProvider } from 'react-stripe-elements'

import { ThemeProvider } from 'styled-components'
import theme from './theme'

import { ToastContainer } from 'react-toastify'

import StickyHeader from 'react-sticky-header'

import {
  Box
} from 'rebass'

import Hide from 'hidden-styled'

import Header from './header'
import Footer from './footer'
import FreeTrialModal from './freeTrialModal'

import './layout.css'

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = { stripe: null }
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      if (window.Stripe) {
        this.setState({stripe: window.Stripe('pk_test_eSTxeFDrPm3zVgIQu4UDrQaI')})
      } else {
        document.addEventListener('DOMContentLoaded', function(e) {
          document.querySelector('#stripe-js').addEventListener('load', () => {
            this.setState({stripe: window.Stripe('pk_live_YMxMJoDV9CyFqcc1tiECYNeP')})
          });
        });
      }
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: 'Reverse diabetes and lose weight with a clinically-proven program.' },
                { name: 'keywords', content: 'Diabetes, Keto, Weight Loss, LCHF, Low Carb High Fat, Clinically Proven' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=no, minimum-scale=1, maximum-scale=1' },
                { name: 'msapplication-TileColor', content: '#ffc40d' },
                { name: 'theme-color', content: '#ffffff' }
              ]}
              link={[
                { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
                { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
                { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
                { rel: 'manifest', href: '/site.webmanifest' },
                { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#5bbad5' },
              ]}
            >
              <script
                dangerouslySetInnerHTML={{ __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1416765115122509');
                fbq('track', 'PageView');
                `}}
              />
              <noscript>
                {`
                <img alt='Facebook pixel' height="1" width="1" style={{ display: 'none' }}
                     src="https://www.facebook.com/tr?id=1416765115122509&ev=PageView&noscript=1"/>
                `}
              </noscript>
              <script id="stripe-js" src="https://js.stripe.com/v3/" async></script>
              <html lang="en" />
            </Helmet>
            <ThemeProvider theme={theme}>
              <StripeProvider stripe={this.state.stripe}>
                <div>
                  <ToastContainer />
                  <StickyHeader
                    header={
                      <Header siteTitle={data.site.siteMetadata.title} />
                    }
                  />
                  <Box mt={[5, 6]}>
                    {this.props.children}
                  </Box>
                  <Hide md lg>
                    <FreeTrialModal variant='mobile' />
                  </Hide>
                  <Box mb={[5,0]}>
                    <Footer />
                  </Box>
                </div>
              </StripeProvider>
            </ThemeProvider>
          </>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

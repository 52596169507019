const blue = 'rgba(39, 110, 241, 1.0)'
const green = 'rgba(121, 187, 67, 1.0)'
const lightGray = '#f6f6f4'
const gray = '#525f7f'

export default {
  fonts: {
    sans: '"Graphik", sans-serif',
    serif: 'serif'
  },
  colors: {
    blue: blue,
    green: green,
    lightGray: lightGray,
    gray: gray
  },
  Heading: {
    lineHeight: 1.25
  },
  buttons: {
    primary: {
      color: '#fff',
      backgroundColor: blue,
      boxShadow: '0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)',
      transition: 'all 0.15s ease',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 7px 14px rgba(50, 50, 93, .1), 0 3px 6px rgba(0, 0, 0, .08)'
      }
    },
    fullLength: {
      borderRadius: 0,
      width: '100%',
      backgroundColor: blue,
      padding: '0.5em 0'
    },
    cta: {
      width: '100%',
      backgroundColor: blue
    }
  },
  cards: {
    primary: {
      boxShadow: '0 4px 6px rgba(50,50,93,.11),0 1px 3px rgba(0,0,0,.08)',
      transition: 'all 0.15s ease',
    },
  },
  Button: {
    fontFamily: '"Graphik", sans-serif',
    cursor: 'pointer'
  },
  Text: {
    fontFamily: '"Graphik", sans-serif',
    lineHeight: 1.25
  },
  Link: {
    color: blue,
    textDecoration: 'none',
    transition: 'all 0.15s ease',
    '&:hover': {
      color: 'blue'
    }
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)'
  }
}

import React, {Component} from 'react'
import {CardElement, injectStripe} from 'react-stripe-elements'
import { toast } from 'react-toastify'

import {
  Button,
  Box,
  Text,
  Link
} from 'rebass'

import privacyPolicy from '../files/reversity_privacy_policy.pdf'
import termsOfService from '../files/reversity_terms_of_service.pdf'

class PaymentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {email: '', fullName: '', submitting: false, termsAgreedTo: false}
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleTermsAgreementChange = this.handleTermsAgreementChange.bind(this)
    this.submit = this.submit.bind(this)
  }

  handleNameChange(e) {
    this.setState({fullName: e.target.value})
  }

  handleEmailChange(e) {
    this.setState({email: e.target.value})
  }

  handleTermsAgreementChange(e) {
    this.setState({termsAgreedTo: !this.state.termsAgreedTo})
  }

  async submit(e) {
    if (!this.state.termsAgreedTo) {
      toast.error("You must agree to Reversity's Terms of Service and Privacy Policy before continuing.", {
        position: toast.POSITION.TOP_CENTER
      })
    } else {
      this.setState({submitting: true})
      if (this.state.email !== '' && this.state.fullName !== '') {
        let {source} = await this.props.stripe.createSource({
          type: 'card',
          currency: 'usd',
          owner: {
            name: this.state.fullName
          }
        })

        let response = await fetch('https://09tgeiy3l8.execute-api.us-west-2.amazonaws.com/beta/subscriptions', {
          method: 'POST',
          body: JSON.stringify({
            "full_name": this.state.fullName,
            "email":     this.state.email,
            "source":    source.id,
            "plan_name": this.props.planName
          })
        })

        if (response.ok) {
          this.setState({submitting: false})
          this.props.modal.hide()

          toast.success("Payment information successfully submitted. Reversity will contact you shortly!", {
            position: toast.POSITION.TOP_CENTER,
          })
        } else {
          this.setState({submitting: false})
          toast.error("Your payment information could not be processed. Please double check your information and try again.", {
            position: toast.POSITION.TOP_CENTER,
          })
        }
      } else {
        this.setState({submitting: false})
        toast.error("Please fill in your name, email, and card number and try again.", {
          position: toast.POSITION.TOP_CENTER
        })
      }
    }
  }

  render() {
    return (
      <div>
        <Box bg='lightGray' mb={3} p={3}>
          <Box py={1}>
            <label>
              <Text py={1} color='gray' fontSize={1}>Full name</Text>
              <input style={{ width: '100%', fontSize: '16px', lineHeight: '18px', '::placeholder': { color: '#aab7c4', }, color: '#32325d' }} type='text' className='field' placeholder='John Appleseed' name='full_name' value={this.state.fullName} onChange={this.handleNameChange} required={true} />
            </label>
          </Box>

          <Box py={1}>
            <label>
              <Text py={1} color='gray' fontSize={1}>Email</Text>
              <input style={{ width: '100%', fontSize: '16px', lineHeight: '18px', '::placeholder': { color: '#aab7c4', }, color: '#32325d' }} type='email' className='field' placeholder='japple@seed.com' name='email' value={this.state.email} onChange={this.handleEmailChange} required={true} />
            </label>
          </Box>

          <Box py={1}>
            <label>
              <Text py={1} color='gray' fontSize={1}>Card number</Text>
              <CardElement style={{
                base: {
                  color: '#32325d',
                  lineHeight: '18px',
                  fontSize: '16px',
                  '::placeholder': {
                    color: '#aab7c4'
                  },
                },
                invalid: {
                  color: '#fa755a',
                  iconColor: '#fa755a'
                }
              }} />
            </label>
          </Box>
        </Box>
        <Box mb={3}>
          <Text color='gray' fontSize={1}>
            <input style={{ marginRight: '0.5rem' }} type='checkbox' name='termsCheckbox' value={this.state.termsAgreedTo} onChange={this.handleTermsAgreementChange} />
            I agree to Reversity's <Link href={termsOfService} target='_blank' rel='noopener noreferrer'>Terms of Service</Link> and <Link href={privacyPolicy} target='_blank' rel='noopener noreferrer'>Privacy Policy</Link>.
          </Text>
        </Box>
        <Button variant='primary' onClick={this.submit}>{this.state.submitting ? 'Submitting...' : 'Submit'}</Button>
      </div>
    )
  }
}

export default injectStripe(PaymentForm)

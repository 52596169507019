import React from 'react'
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'

import { Elements } from 'react-stripe-elements'

import {
  Box,
  Button,
  Heading,
  Text
} from 'rebass'

import PaymentForm from './paymentForm'

class ReversityUniversityModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
    this.hide = this.hide.bind(this)
    this.show = this.show.bind(this)
  }

  show() {
    this.setState({ visible: true })
  }

  hide() {
    this.setState({ visible: false })
  }

  render() {
    return (
      <div>
        <Button
          variant='primary'
          onClick={this.show}>
          Subscribe now
        </Button>
        <Rodal
          visible={this.state.visible}
          onClose={this.hide}
          closeOnEsc={true}
          animation='slideDown'>
          <Box style={{ textAlign: 'left' }}>
            <Heading>Reversity University</Heading>
            <Text color='gray' fontWeight='bold'>$49.99/month</Text>
            <Text color='gray' py={2} fontSize={1}>As a Reversity University subscriber, you will receive a weekly set of hand-picked educational resources to support their specific health goals and deepen their understanding of the science of diabetes reversal. Send a request to contact@reversity.io to cancel at any time.</Text>
            <Elements>
              <PaymentForm modal={this} planName='reversity_university' />
            </Elements>
          </Box>
        </Rodal>
      </div>
    )
  }
}

export default ReversityUniversityModal
